import React, { useEffect, useRef } from "react"
import { withOrientationChange } from "react-device-detect"

import Button from "@/components/core/Button/Button"
import BackArrow from "@/public/icons/back-arrow.svg"

import { sanitizeInnerHtml } from "@/utils/helper"
import { getEventInfo } from "@/components/ContactUs/v1/contactUsAnalytics"

const ContactUsConfirmation = props => {
  const {
    setSubmitSuccess = () => {},
    i18n = {},
    continueShoppingUrl = "",
  } = props
  const successMessageRef = useRef(null)

  useEffect(() => {
    successMessageRef?.current?.focus()
    window?.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }, [])

  return (
    <div className="kf-react-container">
      <div className="contact-us__success-message">
        <button
          className="back gbh-data-layer"
          data-gbh-data-layer={getEventInfo("back to contact us")}
          onClick={() => setSubmitSuccess(false)}
        >
          <img src={BackArrow?.src} alt="Back" />
          {i18n?.backToContactUs}
        </button>
        <div
          ref={successMessageRef}
          id="contact-us-success-message"
          className="message-content"
          tabIndex="0"
        >
          <p className="title">{i18n?.messageSent}</p>
          <p className="details">
            {sanitizeInnerHtml(i18n?.responseReceiveTime)}
          </p>
        </div>
        <Button
          type="black"
          label={i18n?.continueShopping}
          to={continueShoppingUrl}
          className="gbh-data-layer contact-us__continue-shopping-btn"
          data-gbh-data-layer={getEventInfo(
            "continue shopping",
            continueShoppingUrl
          )}
          useDefaultClass={true}
        />
      </div>
    </div>
  )
}

export default withOrientationChange(ContactUsConfirmation)
