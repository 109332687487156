import React from "react"

import { validateField } from "@/utils/helper"

import Input from "@/components/core/Input/Input"
import RadioButton from "@/components/core/RadioButton/RadioButton"

const WithProfessional = props => {
  const {
    i18n = {},
    data = {},
    setData = () => {},
    radioKeyDown = () => {},
  } = props
  return (
    <div
      className="optional-form border-0"
      role="radiogroup"
      aria-labelledby="with-professional-radio"
    >
      <h3 id="with-professional-radio" className="question">
        {i18n?.workingWithProfessional}
      </h3>
      <div className="radio-wrapper">
        <RadioButton
          id={"with-professional"}
          name={"with-professional"}
          value={i18n?.yes}
          checked={data?.active}
          onClick={() => setData({ active: true })}
          onKeydown={radioKeyDown}
        />
        <RadioButton
          id={"without-professional"}
          name={"with-professional"}
          value={i18n?.no}
          checked={!data?.active}
          onClick={() => setData({ active: false })}
          onKeydown={radioKeyDown}
        />
      </div>
      {data?.active && (
        <div className="form-wrapper">
          <Input
            id="kf-professional-company"
            type="text"
            value={data?.company || ""}
            maxLength={50}
            placeholder={`${i18n?.companyName}`}
            label={`${i18n?.companyName}`}
            onChange={async e => {
              const { value } = e.target
              if (
                (await validateField("contactusCompanyName", value)) ||
                value === ""
              ) {
                setData({ ...data, company: value })
              }
            }}
          />
        </div>
      )}
    </div>
  )
}

export default WithProfessional
