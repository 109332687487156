export const getAnalyticsData = (
  activeTab,
  formError,
  submitSuccess,
  isForSubButton,
  formErrorFromServer,
  isSuccessStatus
) => {
  const internalLinkType = isForSubButton
    ? "support:contact:submit"
    : "support:contact:navigation"
  const eventType = isForSubButton ? "submit" : "upload file"
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `support:contact:form:${activeTab?.toLowerCase()}:${eventType}`,
    eventMsg: isForSubButton
      ? formErrorFromServer
        ? formErrorFromServer
        : formError
      : "n/a",
    eventName: `support:contact:form:${activeTab?.toLowerCase()}:${eventType}`,
    eventStatus: isForSubButton
      ? submitSuccess || isSuccessStatus
        ? "success"
        : "failure"
      : "n/a",
    eventType: "navigation",
    internalLinkName: eventType,
    internalLinkPosition: "contact us",
    internalLinkType,
    internalLinkURL: "n/a",
    internalLinkZoneName: "support:contact:form",
  }
  return JSON.stringify(eventInfo)
}

export const addFormAnalyticsData = (
  activeTab,
  submitSuccess,
  formError,
  isSuccessStatus
) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const eventInfo = getAnalyticsData(
    activeTab,
    formError,
    submitSuccess,
    true,
    formError,
    isSuccessStatus
  )
  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo: JSON.parse(eventInfo),
    page: page,
  })
}

export const getFooterEventInfo = (type, link, clickInternalLinks = "true") => {
  return JSON.stringify({
    eventAction: "support:contact:form:footer:" + type,
    eventName: "support:contact:form:footer:" + type,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: type,
    internalLinkPosition: "contact us",
    internalLinkType: "support:contact:navigation",
    internalLinkZoneName: "support:contact:form:footer",
    internalLinkURL: link
      ? (link.indexOf("http") === -1 ? window.location.origin : "") + link
      : "n/a",
    clickInternalLinks,
  })
}

export const getRecaptchaAnalytics = {
  pageTitle: "support:contact",
  internalLinkPosition: "contact us",
  internalLinkType: "support:contact:navigation",
}
