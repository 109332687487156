import { useEffect, useState } from "react"
import moment from "moment"
import DatePicker, { registerLocale } from "react-datepicker"
import { fr } from "date-fns/locale/fr"
import { useTranslation } from "next-i18next"

import { checkAlphanumeric, validateField } from "@/utils/helper"

import calenderIcon from "@/public/icons/calendar.svg"

import Input from "@/components/core/Input/Input"
import RadioButton from "@/components/core/RadioButton/RadioButton"
import Dropdown from "@/components/core/SelectDropdown/SelectDropdown"

import {
  addDropdownAnalytics,
  getEventInfo,
} from "@/components/ContactUs/v1/Form/contactUsFormAnalytics"

const ProductInstalled = props => {
  const [userInput, setUserInput] = useState({ target: { value: "", id: "" } })
  const {
    data = {},
    setData = () => {},
    error = {},
    i18n = {},
    radioKeyDown = () => {},
    setError = () => {},
    authorData = {},
    getModelNumberSuggestions = () => {},
    helpTopic = [],
    onBlur = () => {},
    productTypes = [],
    modelSuggestions = [],
    onSuggestionSelect = () => {},
    invalidModelNumber = false,
    placeOfPurchase = [],
  } = props
  const localeLanguage = useTranslation()
  if (localeLanguage?.i18n?.language == "fr") {
    fr.options.weekStartsOn = 0
    registerLocale("fr", fr)
  }
  const onInputChange = e => {
    const { target: { value = "", id = "" } = {} } = e
    const field = id?.split("-")
    setData({
      ...data,
      [field[2]]: value,
    })
  }

  const handleModelNumberValidation = async () => {
    const userInputValue = userInput?.target?.value
    if (
      (await validateField("modelNumber", userInputValue)) ||
      userInputValue === ""
    ) {
      onInputChange(userInput)
      if (userInputValue?.length > 2) {
        getModelNumberSuggestions(userInputValue)
      }
    }
  }
  useEffect(() => {
    handleModelNumberValidation()
  }, [userInput])

  const handleModelNumber = e => {
    const { target: { value = "", id = "" } = {} } = e
    setUserInput({ target: { value: value, id: id } })
  }

  const changeDateFormat = date => {
    if (date) {
      const dateVal = moment(date).format("YYYY-MM-DD")
      setData({ ...data, installDate: dateVal })
      setError({})
    }
  }

  const handleProductType = productType => {
    setData({ ...data, productType })
    setError({
      ...error,
      productType: false,
    })
  }

  return (
    <div className="optional-form">
      <div role="radiogroup" aria-labelledby="installed-radio">
        <h3 id="installed-radio" className="question">
          {i18n?.needHelp}
        </h3>
        <div className="radio-wrapper">
          <RadioButton
            id={"installed"}
            name={"installed"}
            value={i18n?.yes}
            checked={data?.active}
            analyticsData={getEventInfo("yes")}
            onClick={() => setData({ ...data, active: true })}
            onKeydown={radioKeyDown}
          />
          <RadioButton
            id={"not-installed"}
            name={"installed"}
            value={i18n?.no}
            checked={!data?.active}
            analyticsData={getEventInfo("no")}
            onClick={() => setData({ ...data, active: false })}
            onKeydown={radioKeyDown}
          />
        </div>
      </div>
      {!data?.active ? (
        <div className="form-wrapper">
          <Dropdown
            id="contact-installed-helpTopic"
            data={helpTopic}
            value={data?.helpTopic}
            label={`${i18n?.howCanWeHelp}*`}
            onChange={helpTopic => {
              addDropdownAnalytics(helpTopic?.toLowerCase())
              setData({ ...data, helpTopic })
              setError({
                ...error,
                helpTopic: false,
              })
            }}
            onBlur={() => onBlur("helpTopic")}
            showError={error?.helpTopic}
            errorMessage={error?.helpTopic}
          />
        </div>
      ) : (
        <div className="form-wrapper">
          <Dropdown
            id="contact-installed-productTypes"
            customClass="input-field"
            data={productTypes}
            value={data?.productType}
            label={`${i18n?.selectProductType}*`}
            onChange={productType => handleProductType(productType)}
            showError={error?.productType}
            onBlur={() => onBlur("productType")}
            errorMessage={error?.productType}
          />
          <Input
            id="contact-installed-modelNumber"
            type="text"
            value={data?.modelNumber}
            maxLength={40}
            placeholder={`${i18n?.modelNumber}`}
            label={`${i18n?.modelNumber}`}
            onChange={handleModelNumber}
            suggestions={modelSuggestions}
            onSelect={val => onSuggestionSelect(val)}
            showError={invalidModelNumber}
            errorMessage={i18n?.productNumberError}
          />
          {authorData?.linkText && (
            <a
              href={authorData?.link}
              className="modal-link gbh-data-layer"
              data-gbh-data-layer={getEventInfo(
                "where do i find my product model number"
              )}
              role="link"
              tabIndex="0"
              target={authorData?.linkToNewTab ? "_blank" : "_self"}
              rel="noreferrer"
            >
              {authorData?.linkText}
            </a>
          )}
          <Input
            id="contact-installed-serial"
            type="text"
            value={data?.serial}
            maxLength={40}
            placeholder={`${i18n?.serialNumber}`}
            label={`${i18n?.serialNumber}`}
            onChange={e => {
              const { value } = e.target
              if (checkAlphanumeric(value) || value === "") {
                onInputChange(e)
              }
            }}
          />
          <div className="mb-20">
            <Dropdown
              id="contact-installed-purchase"
              data={placeOfPurchase}
              value={data?.purchase}
              label={`${i18n?.placeOfPurchaseType}`}
              onBlur={onBlur}
              onChange={purchase => setData({ ...data, purchase })}
            />
          </div>
          <div className="kf-react-datepicker">
            <DatePicker
              locale={localeLanguage?.i18n?.language === "fr" ? fr : "en"}
              value={Date.parse(data?.installDate)}
              selected={
                data?.installDate === ""
                  ? new Date()
                  : Date.parse(data?.installDate)
              }
              dateFormat="MM/dd/yyyy"
              customInput={
                <Input
                  id={props?.id}
                  prefix={<img src={calenderIcon?.src} alt="calendar-icon" />}
                  value={props?.value}
                  placeholder={`${i18n?.installationDate}`}
                  label={`${i18n?.installationDate}`}
                  showError={error?.installDate}
                  errorMessage={error?.installDate}
                  onChange={props?.onChange}
                  onBlur={props?.onBlur}
                />
              }
              id={"contact-installed-installDate"}
              onChange={date => changeDateFormat(date)}
              minDate={new Date("01/01/2001")}
              maxDate={new Date()}
              onBlur={() => props?.onBlur("installDate")}
              isClearable={!!data?.installDate}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default ProductInstalled
