export const addDropdownAnalytics = val => {
  const eventInfo = getEventInfo("how can we help you", false, true, val)

  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    page,
  })
}

export const getEventInfo = (
  value,
  needJson = true,
  interLinkTypeSameAsVal = false,
  internalLinkNameVal
) => {
  const internalLinkType = interLinkTypeSameAsVal
    ? `support:contact:${value}`
    : "support:contact:navigation"
  const internalLinkName = internalLinkNameVal ? internalLinkNameVal : value

  const eventInfo = {
    eventAction: `support:contact:form:${value}`,
    eventName: `support:contact:form:${value}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName,
    internalLinkPosition: "contact us",
    internalLinkType,
    internalLinkZoneName: "support:contact:form",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }
  if (needJson) return JSON.stringify(eventInfo)
  return eventInfo
}
