import React, { useState, useEffect } from "react"
import _isEmpty from "lodash/isEmpty"
import _startCase from "lodash/startCase"
import { withOrientationChange } from "react-device-detect"

import { apim } from "@/constants/api"
import {
  validateField,
  validatePhone,
  getSiteWideModal,
  sanitizeObject,
  getRandom,
  getLangCode,
} from "@/utils/helper"
import CONSTANTS, { CONTACT_US_VALIDATE_RECAPTCHA_ENDPOINT } from "@/constants"

import Button from "@/components/core/Button/Button"
import FormTabs from "@/components/ContactUs/v1/FormTabs"
import UploadImage from "@/components/ContactUs/v1/UploadImage"
import ContactInfo from "@/components/ContactUs/v1/Form/ContactInfo"
import ProductInstalled from "@/components/ContactUs/v1/Form/ProductInstalled"
import WithProfessional from "@/components/ContactUs/v1/Form/WithProfessional"
import ContactUsConfirmation from "@/components/ContactUs/v1/ContactUsConfirmation"

import ReCaptcha, {
  validateRecaptcha,
} from "@/components/core/ReCaptcha/ReCaptcha"

import contactUsStyle from "@/components/ContactUs/v1/ContactUsView/index.module.scss"
import styles from "@/components/core/TextArea/index.module.scss"
import {
  getAnalyticsData,
  addFormAnalyticsData,
  getFooterEventInfo,
  getRecaptchaAnalytics,
} from "@/components/ContactUs/v1/ContactUsView/contactUsViewAnalytics"
import { getConfig } from "@/constants/config"
import useSearchi18n from "@/i18n/useSearchi18n"

const ContactUs = props => {
  const [error, setError] = useState({})
  const [userData, setUserData] = useState({
    fname: "",
    lname: "",
    email: "",
    cemail: "",
    address: "",
    aptFloor: "",
    city: "",
    state: {},
    zipcode: "",
    country: {},
    phone: "",
    company: "",
    businessType: "",
    subType: "",
  })
  const [homeOwnerData, setHomeOwnerData] = useState({
    fname: "",
    lname: "",
    email: "",
    cemail: "",
    address: "",
    aptFloor: "",
    city: "",
    state: {},
    zipcode: "",
    country: {},
    phone: "",
    company: "",
    businessType: "",
    subType: "",
  })
  const [professionalData, setProfessionalData] = useState({
    fname: "",
    lname: "",
    email: "",
    cemail: "",
    address: "",
    aptFloor: "",
    city: "",
    state: {},
    zipcode: "",
    country: {},
    phone: "",
    company: "",
    businessType: "",
    subType: "",
  })
  const {
    CREATE_ACCOUNT_TYPE: { HOME_OWNER = "", PROFESSIONAL = "" },
  } = CONSTANTS
  const HOME_OWNER_TAB = _startCase(HOME_OWNER)
  const PROFESSIONAL_TAB = _startCase(PROFESSIONAL)
  const { brand } = useSearchi18n({})
  useEffect(() => {
    if (activeTab === HOME_OWNER_TAB) {
      setHomeOwnerData(userData)
    } else {
      setProfessionalData(userData)
    }
  }, [userData])
  useEffect(() => {
    getConfig().then(config => {
      const langCode = getLangCode(config)
      const siteName = config?.general?.siteName
      setSiteName(siteName)
      setLangCode(langCode)
    }, [])
  })
  const [installed, setInstalled] = useState({
    active: true,
    helpTopic: "",
    productType: "",
    modelNumber: "",
    serial: "",
    purchase: "",
    installDate: "",
  })
  const [professional, setProfessional] = useState({
    active: true,
    company: "",
  })
  const [requestMessage, setRequestMessage] = useState("")
  const [imageFiles, setImageFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState("")
  const [count, setCount] = useState(0)
  const [langCode, setLangCode] = useState(process.env.NEXT_PUBLIC_LOCALE)
  const [siteName, setSiteName] = useState(null)
  const {
    i18n = {},
    submitSuccess = false,
    setActiveTab = () => {},
    activeTab = "",
    clearModelSuggestions = () => {},
    businessTypes = [],
    sourceKey = "",
    handleSubmitMsg = () => {},
    showToaster = () => {},
    helpTopic = [],
    productTypes = [],
    getModelNumberSuggestions = () => {},
    modelSuggestions = [],
    placeOfPurchase = [],
    invalidModelNumber = false,
    authorData = {},
    legalPages = {},
    continueShoppingUrl = "",
  } = props

  const contactInfoLabels = {
    zipCodeLabel: authorData?.zipCodeLabel,
    stateLabel: authorData?.stateLabel,
  }
  const validateExist = async field => {
    switch (field) {
      case "fname":
      case "lname":
        if (
          !userData[field]?.trim() ||
          !(await validateField("name", userData[field]))
        ) {
          return field === "fname" ? i18n?.fnameError : i18n?.lnameError
        }
        break
      case "email":
        if (!userData[field]) return i18n?.noEmailError
        else if (!(await validateField("email", userData[field])))
          return i18n?.validEmail
        break
      case "cemail":
        if (!userData[field]) return i18n?.noEmailError
        else if (!(await validateField("email", userData[field])))
          return i18n?.validEmail
        else if (userData?.email !== userData?.cemail)
          return i18n?.emailMatchError
        break
      case "phone":
        if (!userData?.phone) return i18n?.noPhoneError
        else if (!validatePhone(userData?.phone)) return i18n?.validPhone
        break
      case "address":
        if (!userData?.address?.trim()) return i18n?.addressError
        break
      case "city":
        if (!userData?.city?.trim()) return i18n?.cityError
        break
      case "state":
        if (!userData?.state || _isEmpty(userData?.state))
          return i18n?.stateError
        break
      case "zipcode":
        if (!userData.zipcode.trim()) return i18n.zipcodeError
        else if (userData.zipcode.length < 5) return i18n.validZipcode
        break
      case "installDate":
        if (!installed?.installDate) return i18n?.installationDateValidError
        break
      case "company":
        if (!userData?.company) return i18n?.noCompanyError
        break
      case "businessType":
        if (!userData?.businessType || _isEmpty(userData?.businessType))
          return i18n?.businessTypeError
        break
      case "subType":
        if (!userData?.subType) return i18n?.subTypeError
        break
      case "helpTopic":
        if (
          !installed?.active &&
          (!installed?.helpTopic || _isEmpty(installed?.helpTopic))
        )
          return i18n?.helpTopic
        break
      case "productType":
        if (
          installed?.active &&
          (!installed?.productType || _isEmpty(installed?.productType))
        )
          return i18n?.productType
        break
      default:
        return
    }
  }

  const onSuggestionSelect = val => {
    setInstalled({ ...installed, modelNumber: val })
    clearModelSuggestions()
  }

  const onBlurError = async field => {
    setError({
      ...error,
      [field]: await validateExist(field),
    })
  }

  const radioKeyDown = e => {
    const { target: radioTarget = {}, key: radioKey = "" } = e
    const { nextSibling, previousSibling } = radioTarget
    const nextRadio = nextSibling
    const previousRadio = previousSibling
    if (radioKey === "ArrowLeft" && previousRadio) {
      previousRadio?.focus()
    } else if (radioKey === "ArrowRight" && nextRadio) {
      nextRadio?.focus()
    } else if (radioKey === "Enter" || radioKey === " ") {
      e?.preventDefault()
      radioTarget?.click()
    }
  }

  const getErrorList = async () => {
    const errorList = {
      fname: await validateExist("fname"),
      lname: await validateExist("lname"),
      email: await validateExist("email"),
      cemail: await validateExist("cemail"),
      address: await validateExist("address"),
      city: await validateExist("city"),
      state: await validateExist("state"),
      zipcode: await validateExist("zipcode"),
      phone: await validateExist("phone"),
      helpTopic: await validateExist("helpTopic"),
      productType: await validateExist("productType"),
    }

    if (activeTab === PROFESSIONAL_TAB) {
      errorList.company = await validateExist("company")
      errorList.businessType = await validateExist("businessType")
      const subTypeList = !errorList?.businessType
        ? businessTypes
            ?.find(el => el.text === userData?.businessType)
            ?.value?.split(",")
            ?.filter(e => e)
        : []
      if (subTypeList?.length > 0 && !userData?.subType) {
        errorList.subType = i18n?.subTypeError
      }
    }
    return errorList
  }

  const onSubmit = async () => {
    setLoading(true)
    const errorDataList = []
    const errorList = await getErrorList()
    const imageLinks = imageFiles?.map(image => ({
      link: image?.link,
      comment: image?.comment,
    }))
    setError(errorList)

    if (errorList?.fname) {
      errorDataList.push(`contact-user-fname`)
    } else if (errorList?.lname) {
      errorDataList.push(`contact-user-lname`)
    } else if (errorList?.email) {
      errorDataList.push(`contact-user-email`)
    } else if (errorList?.cemail) {
      errorDataList.push(`contact-user-cemail`)
    } else if (errorList?.address) {
      errorDataList.push(`contact-user-address`)
    } else if (errorList?.city) {
      errorDataList.push(`contact-user-city`)
    } else if (errorList?.state) {
      errorDataList.push(`contact-user-state`)
    } else if (errorList?.zipcode) {
      errorDataList.push(`contact-user-zipcode`)
    } else if (errorList?.phone) {
      errorDataList.push(`contact-user-phone`)
    } else if (errorList?.helpTopic) {
      errorDataList.push(`contact-installed-helpTopic`)
    } else if (errorList?.company) {
      errorDataList.push(`contact-user-company`)
    } else if (errorList?.businessType) {
      errorDataList.push(`contact-form-businessType`)
    } else if (errorList?.productType) {
      errorDataList.push(`contact-installed-productTypes`)
    }

    errorDataList.forEach((item, i) => {
      if (i === 0) document.getElementById(`${item}`)?.focus()
    })

    const isInvalid = Object.values(errorList)?.find(el => el)
    if (!isInvalid) {
      const {
        fname,
        lname,
        email,
        address,
        aptFloor,
        city,
        state,
        zipcode,
        country,
        phone,
        company,
        businessType,
        subType,
      } = userData

      const body = {
        FirstName: fname,
        LastName: lname,
        Email: email,
        Address1: address,
        Address2: aptFloor,
        City: city,
        StateProvince: state?.code,
        PostalCode: zipcode,
        Country: country?.code,
        Phone: phone,
        AttachmentLinks: imageLinks,
        CustomerType: activeTab,
        InteractionType: "ContactUs",
        QuestionAboutInstalledProduct: installed?.active,
        DescribeYourRequest: requestMessage,
        SourceKey: [
          {
            Type: sourceKey,
            Value: `${getRandom()}`,
            CreateDate: new Date().toISOString(),
            UpdateDate: new Date().toISOString(),
          },
        ],
        Website: sourceKey,
        ...(siteName !== brand ? { LangCode: langCode } : {}),
      }

      if (activeTab === PROFESSIONAL_TAB) {
        body["Company"] = company
        body["BusinessType"] = businessType
        body["BusinessSubtype"] = subType
      }

      if (activeTab === HOME_OWNER_TAB && professional?.active) {
        body["WorkingWithAnotherProfessional"] = professional?.active
        if (professional?.active)
          body["OtherProfessionalCompany"] = professional?.company
      }

      if (installed?.active) {
        body["ProductType"] = installed?.productType
        body["PlaceOfPurchaseType"] = installed?.purchase
        body["InstallationDate"] = installed?.installDate
        body["ProductSerialNumber"] = installed?.serial
        body["ModelNumber"] = installed?.modelNumber?.startsWith("K-")
          ? installed?.modelNumber?.slice(2)
          : installed?.modelNumber
      } else {
        body["TopicSelected"] = installed?.helpTopic
      }

      sanitizeObject(body)

      validateRecaptcha().then(res => {
        if (res?.success) {
          apim
            .post(CONTACT_US_VALIDATE_RECAPTCHA_ENDPOINT, body)
            .then(() => {
              handleSubmitMsg(true)
              addFormAnalyticsData(activeTab, submitSuccess, "", true)
              setTimeout(() => {
                setActiveTab(HOME_OWNER_TAB)
              }, 0)
            })
            .catch(err => {
              setFormError(i18n?.serverError)
              addFormAnalyticsData(activeTab, submitSuccess, i18n?.serverError)
              setLoading(false)
              // eslint-disable-next-line no-console
              console.log(err)
            })
        } else {
          setLoading(false)
          addFormAnalyticsData(activeTab, submitSuccess, i18n?.genError)
          showToaster(i18n?.genError)
        }
      })
    } else {
      setLoading(false)
      const allErrors = []
      for (const key in errorList) {
        if (errorList[key]) {
          allErrors?.push(errorList[key]?.toLowerCase())
        }
      }
      addFormAnalyticsData(activeTab, submitSuccess, allErrors?.join("|"))
    }
  }

  const checkDisabled = userdata => {
    if (
      userdata?.fname?.trim() &&
      userdata?.lname?.trim() &&
      userdata?.email?.trim() &&
      userdata?.address?.trim() &&
      userdata?.city?.trim() &&
      userdata?.state &&
      userdata?.country &&
      userdata?.phone &&
      (installed?.active ? installed?.productType : installed?.helpTopic)
    )
      return false
    return true
  }

  return (
    <div className={`${contactUsStyle?.contactUsStyleWrapper}`}>
      {!submitSuccess ? (
        <div className="kf-react-container">
          <div className="contact-us-main">
            <div className="title">
              <h2 className="question">{i18n?.contactInformation}</h2>
              <span className="required-fields">{i18n?.requiredInfoText}</span>
            </div>
            <FormTabs
              label={[HOME_OWNER_TAB, PROFESSIONAL_TAB]}
              customClass="gbh-data-layer"
              analyticsData={`{"eventAction":"support:contact:form:{?}","eventName":"support:contact:form:{?}","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"{?}","internalLinkPosition":"contact us","internalLinkType":"support:contact:contact information","internalLinkZoneName":"support:contact:form","internalLinkURL":"n/a","clickInternalLinks":"true"}`}
              activeTab={activeTab}
              i18n={i18n}
              changeActive={l => {
                setActiveTab(l)
                setUserData(
                  l === HOME_OWNER_TAB ? homeOwnerData : professionalData
                )
                setError({})
              }}
            />
            <ContactInfo
              key={activeTab}
              activeTab={activeTab}
              data={userData}
              labels={contactInfoLabels}
              setData={setUserData}
              error={error}
              i18n={i18n}
              businessTypes={businessTypes}
              onBlur={onBlurError}
              setError={setError}
            />
            <ProductInstalled
              i18n={i18n}
              data={installed}
              setData={setInstalled}
              error={error}
              helpTopic={helpTopic}
              productTypes={productTypes}
              getModelNumberSuggestions={getModelNumberSuggestions}
              modelSuggestions={modelSuggestions}
              onSuggestionSelect={onSuggestionSelect}
              placeOfPurchase={placeOfPurchase}
              onBlur={onBlurError}
              findingModel={props.findingModel}
              radioKeyDown={radioKeyDown}
              invalidModelNumber={invalidModelNumber}
              setError={setError}
              authorData={authorData}
            />
            {activeTab === HOME_OWNER_TAB && (
              <WithProfessional
                i18n={i18n}
                data={professional}
                setData={setProfessional}
                radioKeyDown={radioKeyDown}
              />
            )}
            <div className="optional-form">
              <h3 id="contactus-request-message" className="question">
                {i18n?.describeYourRequest}{" "}
              </h3>
              <p className="description">{i18n?.requestDetail}</p>
              <div className={styles.wrapper}>
                <div className="form-wrapper textarea__wrapper">
                  <textarea
                    className="textarea-message"
                    id="requestMessage"
                    aria-labelledby="contactus-request-message"
                    value={requestMessage}
                    placeholder="Message"
                    onChange={async e => {
                      const { value } = e.target
                      if (
                        (await validateField("contactusMessage", value)) ||
                        value === ""
                      ) {
                        setCount(value?.length)
                        setRequestMessage(value)
                      }
                    }}
                    maxLength="500"
                  />
                  <label aria-hidden="true" className="textare-label-message">
                    {i18n?.messageLabel}
                  </label>
                  {count !== 0 && (
                    <span className="contact-us-count">{count}/500</span>
                  )}
                </div>
              </div>
            </div>
            <div className="optional-form border-0">
              <h3 className="question">{i18n?.uploadPhoto}</h3>
              <p className="description">{i18n?.uploadCountLimit}</p>
              <p className="upload-notes">
                <span>{i18n?.uploadFileTypes}</span>
                <span>{i18n?.uploadFileExtn}</span>
              </p>
              <p className="upload-notes">
                <span>{i18n?.uploadSizeLimit}</span>
                <span>{i18n?.uploadMBLimit}</span>
              </p>
              <UploadImage
                imageList={imageFiles}
                updateList={setImageFiles}
                i18n={i18n}
                activeTab={activeTab}
                buttonAnalyticsData={getAnalyticsData(
                  activeTab,
                  formError,
                  submitSuccess,
                  false
                )}
              />
            </div>
            <div className="contact-us-footer">
              {formError && (
                <p className="contact-us-footer__error-msg">{formError}</p>
              )}
              <Button
                role="button"
                id="submit-contact-form-button"
                disabled={checkDisabled(userData)}
                onClick={onSubmit}
                type="black"
                label={i18n?.submitButton}
                flexible
                loading={loading}
                useDefaultClass={true}
              />
              <ReCaptcha getAnalyticsData={getRecaptchaAnalytics} />
              <div className="contact-us__policies">
                <a
                  href={legalPages?.privacyPolicy}
                  data-gbh-data-layer={getFooterEventInfo(
                    "privacy policy",
                    legalPages?.privacyPolicy
                  )}
                  target="_blank"
                  rel="noreferrer"
                  onClick={e => getSiteWideModal(e, legalPages?.privacyPolicy)}
                >
                  {i18n?.privacyPolicy}
                </a>
                <a
                  href={legalPages?.subjectAccessRequest}
                  data-gbh-data-layer={getFooterEventInfo(
                    "subject access request",
                    legalPages?.subjectAccessRequest,
                    "false"
                  )}
                  target="_blank"
                  rel="noreferrer"
                  onClick={e =>
                    getSiteWideModal(e, legalPages?.subjectAccessRequest)
                  }
                >
                  {i18n?.subjectAccessRequest}
                </a>
                <a
                  href={legalPages?.termsOfService}
                  data-gbh-data-layer={getFooterEventInfo(
                    "terms of service",
                    legalPages?.termsOfService
                  )}
                  target="_blank"
                  rel="noreferrer"
                  onClick={e => getSiteWideModal(e, legalPages?.termsOfService)}
                >
                  {i18n?.termsOfService}
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="contact-us__success-message-wrapper">
          <ContactUsConfirmation
            i18n={i18n}
            setSubmitSuccess={handleSubmitMsg}
            continueShoppingUrl={continueShoppingUrl}
          />
        </div>
      )}
    </div>
  )
}

export default withOrientationChange(ContactUs)
