import React, { useEffect, useState } from "react"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import _startCase from "lodash/startCase"
import debounce from "lodash/debounce"
import { useDispatch } from "react-redux"

import { getConfig } from "@/constants/config"
import CONSTANTS from "@/constants"

import {
  getBusinessList,
  getGenericListPath,
  getCommonList,
  showToast,
} from "@/store/features/genericSlice"
import { getModelNumberSuggest } from "@/store/features/myProductSlice"

import { getShortenedUrl } from "@/utils/helper"
import useContactUsi18n from "@/i18n/useContactUsi18n"

import ContactUsView from "@/components/ContactUs/v1/ContactUsView"

const ContactUs = props => {
  const { data: componentProp } = props
  const { type: sourceKeyType = "", continueShoppingUrl = "" } = componentProp
  const {
    CREATE_ACCOUNT_TYPE: { HOME_OWNER = "" },
  } = CONSTANTS

  const [activeTab, setActiveTabVal] = useState(_startCase(HOME_OWNER))
  const [helpTopic, setHelpTopic] = useState([])
  const [productTypes, setProductTypes] = useState([])
  const [placeOfPurchase, setPlaceOfPurchase] = useState([])
  const [businessTypes, setBusinessTypes] = useState([])
  const [modelSuggestions, setModelSuggestions] = useState([])
  const [invalidModelNumber, setInvalidModelNumber] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [homePagePath, setHomePagePath] = useState()
  const [legalPageLinks, setLegalPageLinks] = useState()
  const [continueShoppingUrlData, setContinueShoppingUrlData] = useState("")
  const i18n = useContactUsi18n(componentProp)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getGenericListPath())
  }, [])
  useEffect(() => {
    getConfig().then(async config => {
      const homePagePath = await getShortenedUrl(
        _get(config, "general.homepageUrl", "/")
      )
      const legalPageLinks = _get(config, "legalPageData.map", {})

      setHomePagePath(homePagePath)
      setLegalPageLinks(legalPageLinks)
      setContinueShoppingUrlData(
        await getShortenedUrl(continueShoppingUrl || "")
      )
    })
  })

  useEffect(() => {
    if (
      _isEmpty(helpTopic) ||
      _isEmpty(productTypes) ||
      _isEmpty(placeOfPurchase) ||
      _isEmpty(businessTypes)
    )
      getListItem()
  }, [helpTopic, productTypes, placeOfPurchase, businessTypes])

  const handleSubmit = status => {
    const container = document.getElementById("aem-contact-us-container")
    status
      ? container?.classList.add("d-none")
      : container?.classList.remove("d-none")
    setSubmitSuccess(status)
  }

  const getListItem = async () => {
    const { payload: commonList } = await dispatch(getCommonList())
    const { payload: businessType } = await dispatch(getBusinessList())

    setHelpTopic(
      commonList?.find(el => el?.text === "helpTopic")?.value?.split(",")
    )
    setProductTypes(
      commonList?.find(el => el?.text === "productTypes")?.value?.split(",")
    )
    setPlaceOfPurchase(
      commonList?.find(el => el?.text === "placeOfPurchase")?.value?.split(",")
    )
    setBusinessTypes(businessType)
  }

  const modelNumberSuggestions = async searchKeyword => {
    const config = await getConfig()
    const language = config?.internationalization?.language ?? "en"
    const params = {
      q: searchKeyword,
      rows: 10,
      fq: [`language_s:("${language}")`, '-Brand_ss:("PlumbingServiceParts")'],
      fl: "variantList.sku_ss,customerFacingSKUList_ss,Brand_ss,Country_ss",
      start: 0,
      skuSearch: true,
    }
    const { suggestions = [], invalidModelNumber = false } = await dispatch(
      getModelNumberSuggest(params)
    ).unwrap()

    setModelSuggestions(suggestions)
    setInvalidModelNumber(invalidModelNumber)
  }

  const getModelNumberSuggestions = debounce(
    key => modelNumberSuggestions(key),
    500
  )

  const clearModelSuggestions = () => setModelSuggestions([])

  const setActiveTab = t => {
    setActiveTabVal(t)
  }

  return (
    <ContactUsView
      key={submitSuccess}
      i18n={i18n}
      helpTopic={helpTopic}
      productTypes={productTypes}
      placeOfPurchase={placeOfPurchase}
      businessTypes={businessTypes}
      sourceKey={sourceKeyType}
      continueShoppingUrl={continueShoppingUrlData}
      invalidModelNumber={invalidModelNumber}
      modelSuggestions={modelSuggestions}
      getModelNumberSuggestions={getModelNumberSuggestions}
      clearModelSuggestions={clearModelSuggestions}
      showToaster={msg =>
        showToast({
          message: msg,
          isVisible: true,
        })
      }
      homePagePath={homePagePath}
      submitSuccess={submitSuccess}
      handleSubmitMsg={handleSubmit}
      legalPages={legalPageLinks}
      setActiveTab={setActiveTab}
      activeTab={activeTab}
      authorData={componentProp}
    />
  )
}

export default ContactUs
