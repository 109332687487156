export const getEventInfo = (type, link) => {
  return JSON.stringify({
    clickInternalLinks: "true",
    eventAction: "support:contact:main content area:" + type,
    eventName: "support:contact:main content area:" + type,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: type,
    internalLinkPosition: "contact us",
    internalLinkType: "support:contact:navigation",
    internalLinkURL: link
      ? (link.indexOf("http") === -1 ? window.location.origin : "") + link
      : "n/a",
    internalLinkZoneName: "support:contact:main content area​",
  })
}

export const getAnalyticsData = (analyticsData, label) => {
  let jsonAnalyticsData = null
  if (analyticsData) {
    jsonAnalyticsData = JSON.parse(analyticsData)
    Object.keys(jsonAnalyticsData).forEach(key => {
      if (jsonAnalyticsData[key]) {
        jsonAnalyticsData[key] = jsonAnalyticsData[key].replace(
          "{?}",
          label?.toLowerCase()
        )
      }
    })
  }
  return JSON.stringify(jsonAnalyticsData)
}

export const analyticsOnDelete = activeTabForAnalytics => {
  const eventInfo = {
    eventAction: `support:contact:form:${activeTabForAnalytics}:remove file`,
    eventName: `support:contact:form:${activeTabForAnalytics}:remove file`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "remove file",
    internalLinkPosition: "support:contact",
    internalLinkType: "support:contact:navigation",
    internalLinkZoneName: "support:contact:form",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo: eventInfo,
    page: page,
  })
}
