import { useTranslation } from "next-i18next"

const useContactUsi18n = componentProps => {
  const { t } = useTranslation()
  const i18n = {
    requiredInfoText: t("kf.auth.label.requiredFields"),
    contactUsDisplayName: t("kf.contactus.label.contactUsDisplayName"),
    contactUsDescription: t("kf.contactus.label.contactUsDescription"),
    installationQuestion: t("kf.contactus.label.installationQuestion"),
    yes: t("kf.contactus.label.yes"),
    no: t("kf.contactus.label.no"),
    howCanWeHelp: t("kf.contactus.label.howCanWeHelp"),
    aTopic: t("kf.contactus.label.aTopic"),
    installationDetails: t("kf.contactus.label.installationDetails"),
    selectProductType: t("kf.contactus.label.selectProductType"),
    serialNumber: t("kf.contactus.label.serialNumber"),
    modelNumber: t("kf.contactus.label.modelNumber"),
    whereToFind: t("kf.registerOfflineProduct.label.whereToFind"),
    kohler: t("kf.registerOfflineProduct.label.kohler"),
    sterlingProduct: t("kf.registerOfflineProduct.label.sterlingProduct"),
    yourorder: t("kf.registerOfflineProduct.label.yourorder"),
    productLabel: t("kf.registerOfflineProduct.label.productLabel"),
    productLiterature: t("kf.registerOfflineProduct.label.productLiterature"),
    invoice: t("kf.registerOfflineProduct.label.invoice"),
    stamped: t("kf.registerOfflineProduct.label.stamped"),
    troubleShooting: t("kf.registerOfflineProduct.label.troubleShooting"),
    findModelNumberQuestion: t("kf.contactus.label.findModelNumberQuestion"),
    placeOfPurchase: t("kf.contactus.label.placeOfPurchase"),
    placeOfPurchaseType: t("kf.contactus.label.placeOfPurchaseType"),
    monthOfInstallation: t("kf.contactus.label.monthOfInstallation"),
    yearOfInstallation: t("kf.contactus.label.yearOfInstallation"),
    contactInformation: t("kf.contactus.label.contactInformation"),
    companyName: t("kf.contactus.label.companyName"),
    homeowner: t("kf.contactus.label.homeowner"),
    professional: t("kf.contactus.label.professional"),
    fname: t("kf.contactus.label.firstName"),
    lname: t("kf.contactus.label.lastName"),
    email: t("kf.contactus.label.emailAddress"),
    cemail: t("kf.contactus.label.confirmEmailAddress"),
    businessType: t("kf.contactus.label.businessType"),
    typeOf: t("kf.contactus.label.typeOf"),
    address: t("kf.contactus.label.address"),
    aptFloorSuite: t("kf.contactus.label.aptFloorSuite"),
    city: t("kf.contactus.label.city"),
    state: t("kf.contactus.label.state"),
    zipcode: t("kf.contactus.label.zipCode"),
    country: t("kf.contactus.label.country"),
    phoneNumber: t("kf.contactus.label.phoneNumber"),
    workingWithProfessional: t("kf.contactus.label.workingWithProfessional"),
    nameOfTheCompany: t("kf.contactus.label.nameOfTheCompany"),
    describeYourRequest: t("kf.contactus.label.describeYourRequest"),
    requestDetail: t("kf.contactus.label.requestDetail"),
    messageLabel: t("kf.contactus.label.messageLabel"),
    uploadPhoto: t("kf.contactus.label.uploadPhoto"),
    uploadCountLimit:
      componentProps?.errorMessage || t("kf.contactus.label.uploadCountLimit"),
    uploadFileTypes: t("kf.contactus.label.uploadFileTypes"),
    uploadFileExtn: t("kf.contactus.label.uploadFileExtn"),
    uploadSizeLimit: t("kf.contactus.label.uploadSizeLimit"),
    uploadMBLimit: t("kf.contactus.label.uploadMBLimit"),
    dragAndDrop: t("kf.contactus.label.dragAndDrop"),
    orText: t("kf.contactus.label.orText"),
    uploadFileButton: t("kf.contactus.label.uploadFileButton"),
    submitButton: t("kf.contactus.label.submitButton"),
    privacyPolicy: t("kf.contactus.label.privacyPolicy"),
    subjectAccessRequest: t("kf.contactus.label.subjectAccessRequest"),
    termsOfService: t("kf.contactus.label.termsOfService"),
    backToContactUs: t("kf.contactus.label.backToContactUs"),
    messageSent:
      componentProps?.messageSent ?? t("kf.contactus.label.messageSent"),
    responseReceiveTime:
      componentProps?.responseReceiveTime ??
      t("kf.contactus.label.responseReceiveTime"),
    continueShopping: t("kf.contactus.label.continueShopping"),
    fnameError: t("kf.contactus.error.fnameError"),
    lnameError: t("kf.contactus.error.lnameError"),
    noEmailError: t("kf.contactus.error.noEmailError"),
    validEmail: t("kf.contactus.error.validEmail"),
    confirmEmailError: t("kf.contactus.error.confirmEmailError"),
    emailMatchError: t("kf.contactus.error.emailMatchError"),
    noCompanyError: t("kf.contactus.error.noCompanyError"),
    businessTypeError: t("kf.contactus.error.businessTypeError"),
    subTypeError: t("kf.contactus.error.subTypeError"),
    addressError: t("kf.contactus.error.addressError"),
    cityError: t("kf.contactus.error.cityError"),
    stateError: t("kf.contactus.error.stateError"),
    zipcodeError: t("kf.contactus.error.zipcodeError"),
    validZipcode: t("kf.contactus.error.validZipcode"),
    cityStateZipMismatchError: t(
      "kf.contactus.error.cityStateZipMismatchError"
    ),
    countryError: t("kf.contactus.error.countryError"),
    validPhone: t("kf.contactus.error.validPhone"),
    noPhoneError: t("kf.contactus.error.noPhoneError"),
    fileLimitExceededError: t("kf.contactus.error.fileLimitExceededError"),
    protectedByGoogle: t("kf.contactus.message.protectedByGoogle"),
    productNumberError: t("kf.contactus.error.productNumberError"),
    apply: t("kf.global.label.apply"),
    errorFileType: t("kf.contactus.error.fileType"),
    errorMaxFileLimit: t("kf.contactus.error.maxFileLimit"),
    errorMaxFileSizeLimit: t("kf.contactus.error.maxFileSizeLimit"),
    installationDate: t("kf.contactus.label.installationDate"),
    installationDateValidError: t(
      "kf.contactus.error.installationDateValidError"
    ),
    serverError: t("kf.contactus.error.serverError"),
    businessAddress: t("kf.contactus.label.businessAddress"),
    businessPhone: t("kf.contactus.label.businessPhone"),
    home: t("kf.global.label.home"),
    helpTopic: t("kf.contactus.error.aTopic"),
    needHelp: t("kf.contactus.label.needHelp"),
    genError: t("kf.error.message.genericError"),
    emailIcon: t("kf.registration.label.email"),
    phone: t("kf.checkout.label.phone"),
    icon: t("kf.technical.label.icon"),
    ariaLabels: {
      imageRemove: t("kf.registerOfflineProduct.label.aria.removeImage"),
    },
    commentBoxPlaceholder: t("kf.registerOfflineProduct.label.addComment"),
    productType: t("kf.contactus.error.productType"),
  }

  return i18n
}

export default useContactUsi18n
