import React, { useState, useEffect, useCallback } from "react"
import debounce from "lodash/debounce"
import _isEmpty from "lodash/isEmpty"
import _startCase from "lodash/startCase"
import { useSelector, useDispatch } from "react-redux"

import emailIcon from "@/public/icons/email.svg"
import phoneIcon from "@/public/icons/phone-icon.svg"

import {
  validateField,
  destructureAddress,
  mapStateToCountryHandler,
} from "@/utils/helper"
import CONSTANTS from "@/constants"

import {
  getCountryList,
  getstateList,
  selectGenericState,
} from "@/store/features/genericSlice"

import Input from "@/components/core/Input/Input"
import Dropdown from "@/components/core/SelectDropdown/SelectDropdown"
import { getAddressSuggestions } from "@/components/checkout/checkoutHelperFunction"
import { convertToPhoneNumber } from "@/components/RegisterOnlineProduct/v1/RegisterOnlineHelper"
import { getConfig } from "@/constants/config"

const ContactInfo = props => {
  const [suggestions, setSuggestions] = useState([])
  const [countries, setCountries] = useState([])
  const [stateList, setStateList] = useState([])
  const [subTypeList, setSubTypeList] = useState([])
  const [addressSelected, setAddressSelected] = useState(false)
  const [userInput, setUserInput] = useState({ target: { value: "", id: "" } })
  const {
    activeTab = "",
    data = {},
    labels = {},
    setData = () => {},
    error = {},
    i18n = {},
    setError = () => {},
    businessTypes = [],
    onBlur = () => {},
  } = props

  const {
    CREATE_ACCOUNT_TYPE: { HOME_OWNER = "", PROFESSIONAL = "" },
  } = CONSTANTS
  const HOME_OWNER_TAB = _startCase(HOME_OWNER)
  const PROFESSIONAL_TAB = _startCase(PROFESSIONAL)
  const isMobile = window.innerWidth <= 480
  const {
    countryListPath,

    stateListPath,
  } = useSelector(selectGenericState)
  const dispatch = useDispatch()
  useEffect(() => {
    setData({ ...data, state: {} })
  }, [data?.country])

  useEffect(() => {
    try {
      if (!_isEmpty(countryListPath) && !_isEmpty(stateListPath)) getLocations()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }, [countryListPath, stateListPath])

  useEffect(() => {
    if (data?.address && !addressSelected) getSuggestions(data?.address)
  }, [data?.address])

  const getLocations = async () => {
    if (countryListPath && stateListPath) {
      const countryData = await dispatch(getCountryList())
      const stateData = await dispatch(getstateList())

      const mapList =
        (!_isEmpty(countryData?.payload) &&
          !_isEmpty(stateData?.payload) &&
          mapStateToCountryHandler(countryData?.payload, stateData?.payload)) ||
        []
      setCountries(mapList)
      const config = await getConfig()
      const countryCode = config?.internationalization?.country ?? "US"
      setStateList(mapList.find(c => c?.code === countryCode)?.states ?? [])
      setData({
        ...data,
        country: mapList.find(country => country?.code === countryCode) ?? {},
      })
    }
  }

  const getSuggestions = useCallback(
    debounce(addr => {
      getAddressSuggestions(addr)?.then(suggData => setSuggestions(suggData))
    }, 300),
    [suggestions]
  )

  const onSuggestionSelect = async val => {
    const { general: { siteName } = {} } = await getConfig()
    const addr = destructureAddress(val, siteName)
    setData({
      ...data,
      address: addr?.address,
      city: addr?.city,
      state: stateList.find(s => s?.code === addr?.state) ?? {},
      zipcode: addr?.zipcode,
    })
    setError({
      ...error,
      address: false,
      city: false,
      state: false,
      zipcode: false,
    })
    setSuggestions([])
    setAddressSelected(true)
  }

  const onInputChange = e => {
    const { target: { value = "", id = "" } = {} } = e
    const field = id?.split("-")
    setData({
      ...data,
      [field[2]]: value,
    })
    setError(prevError => ({
      ...prevError,
      [field[2]]: null,
    }))
    setAddressSelected(false)
  }

  const onNameChange = async e => {
    const { target: { value = "", id = "" } = {} } = e
    const field = id?.split("-")
    if (value === "" || (await validateField("name", value))) {
      setData({
        ...data,
        [field[2]]: value,
      })
      setError(prevError => ({
        ...prevError,
        [field[2]]: null,
      }))
    }
  }

  const changeCountry = country => {
    setStateList(countries?.find(c => c?.name === country)?.states ?? [])
    setData({
      ...data,
      country: countries?.find(c => c?.name === country),
    })
  }
  const changeState = state => {
    setData({ ...data, state: { code: state } ?? {} })
    setError({
      ...error,
      state: false,
    })
  }
  const selectBusinessType = businessType => {
    setData({ ...data, businessType, subType: "" })
    const subTypeL = businessTypes
      ?.find(el => el?.text === businessType)
      ?.value?.split(",")
      ?.filter(e => e)
    setSubTypeList(subTypeL)
    setError({
      ...error,
      businessType: false,
    })
  }
  const selectSubType = subType => {
    setData({ ...data, subType })
    setError({
      ...error,
      subType: false,
    })
  }
  const handleContactUserCompany = e => {
    const { target: { value = "", id = "" } = {} } = e
    setUserInput({ target: { value: value, id: id } })
  }
  const handleContactUserCompanyValidation = async () => {
    const userInputValue = userInput?.target?.value
    if (
      (await validateField("contactusCompanyName", userInputValue)) ||
      userInputValue === ""
    ) {
      onInputChange(userInput)
    }
  }
  useEffect(() => {
    handleContactUserCompanyValidation()
  }, [userInput])

  return (
    <div className="container mb-40">
      <div className="row mx-0">
        <div
          className={`col-12 col-sm-6 col-md-6 pl-0 ${
            isMobile ? "pr-0" : "pr-10"
          }`}
        >
          <Input
            id="contact-user-fname"
            type="text"
            value={data?.fname}
            maxLength={40}
            placeholder={`${i18n?.fname}*`}
            label={`${i18n?.fname}*`}
            onChange={onNameChange}
            showError={error?.fname}
            errorMessage={error?.fname}
            onBlur={() => onBlur("fname")}
          />
        </div>
        <div
          className={`col-12 col-sm-6 col-md-6 pr-0 ${
            isMobile ? "pl-0" : "pl-10"
          }`}
        >
          <Input
            id="contact-user-lname"
            type="text"
            value={data?.lname}
            maxLength={40}
            placeholder={`${i18n?.lname}*`}
            label={`${i18n?.lname}*`}
            onChange={onNameChange}
            showError={error?.lname}
            errorMessage={error?.lname}
            onBlur={() => onBlur("lname")}
          />
        </div>
      </div>
      <div className="row mx-0">
        <div className="col-12 px-0">
          <Input
            id="contact-user-email"
            type="text"
            value={data?.email}
            maxLength={60}
            prefix={<img src={emailIcon?.src} alt="" aria-hidden="true" />}
            placeholder={`${i18n?.email}*`}
            label={`${i18n?.email}*`}
            onChange={onInputChange}
            showError={error?.email}
            errorMessage={error?.email}
            onBlur={() => onBlur("email")}
          />
        </div>
        <div className="col-12 px-0">
          <Input
            id="contact-user-cemail"
            type="text"
            prefix={<img src={emailIcon?.src} alt="" aria-hidden="true" />}
            value={data?.cemail}
            maxLength={60}
            placeholder={`${i18n?.cemail}*`}
            label={`${i18n?.cemail}*`}
            onChange={onInputChange}
            showError={error?.cemail}
            errorMessage={error?.cemail}
            onBlur={() => onBlur("cemail")}
          />
        </div>
      </div>
      {activeTab === PROFESSIONAL_TAB && (
        <div className="row mx-0">
          <div className="col-12 px-0">
            <Input
              id="contact-user-company"
              type="text"
              value={data?.company}
              maxLength={50}
              placeholder={`${i18n?.companyName}*`}
              label={`${i18n?.companyName}*`}
              onChange={handleContactUserCompany}
              showError={error?.company}
              errorMessage={error?.company}
              onBlur={() => onBlur("company")}
            />
          </div>
          <div className="col-12 px-0">
            <Dropdown
              id="contact-form-businessType"
              customClass="input-field"
              data={businessTypes.map(el => el?.text)}
              value={data?.businessType}
              label={`${i18n?.businessType}*`}
              onChange={selectBusinessType}
              showError={error?.businessType}
              errorMessage={error?.businessType}
              onBlur={() => onBlur("businessType")}
            />
          </div>
          {data?.businessType && subTypeList?.length > 0 && (
            <div className="col-12 px-0">
              <Dropdown
                id="contact-form-subType"
                customClass="input-field"
                data={subTypeList}
                value={data?.subType}
                label={`${i18n?.typeOf}${data?.businessType}*`}
                onChange={selectSubType}
                showError={error?.subType}
                errorMessage={error?.subType}
                onBlur={() => onBlur("subType")}
              />
            </div>
          )}
        </div>
      )}
      <div className="row mx-0">
        <div className="col-12 col-md-12 col-lg-9 pl-0 pr-10 mobile-pr-0">
          <Input
            id="contact-user-address"
            type="text"
            value={data?.address}
            placeholder={`${
              activeTab === HOME_OWNER_TAB
                ? i18n?.address
                : i18n?.businessAddress
            }*`}
            label={`${
              activeTab === HOME_OWNER_TAB
                ? i18n?.address
                : i18n?.businessAddress
            }*`}
            onChange={onInputChange}
            showError={error?.address}
            errorMessage={error?.address}
            suggestions={suggestions}
            onSelect={onSuggestionSelect}
            onBlur={() => onBlur("address")}
            autoComplete={true}
          />
        </div>
        <div className="col-12 col-md-12 col-lg-3 col-sm-12 pr-0 pl-10 mobile-pl-0">
          <Input
            id="contact-user-aptFloor"
            type="text"
            value={data?.aptFloor}
            autoComplete={true}
            placeholder={i18n?.aptFloorSuite}
            label={i18n?.aptFloorSuite}
            onChange={onInputChange}
          />
        </div>
      </div>
      <div className="row mx-0">
        <div className="col-12 col-md-12 col-lg-6 pl-0 pr-10 mobile-pr-0">
          <Input
            id="contact-user-city"
            type="text"
            value={data?.city}
            autoComplete={true}
            placeholder={`${i18n?.city}*`}
            label={`${i18n?.city}*`}
            onChange={onInputChange}
            showError={error?.city}
            errorMessage={error?.city}
            onBlur={() => onBlur("city")}
          />
        </div>
        <div className="col-6 col-md-6 col-lg-3 pr-10 pl-10 mobile-pl-0">
          <Dropdown
            id="contact-user-state"
            data={stateList ?? []}
            value={data?.state?.code ?? ""}
            label={`${
              labels && labels.stateLabel ? labels.stateLabel : i18n?.state
            }*`}
            onChange={changeState}
            showError={error?.state}
            errorMessage={error?.state}
            onBlur={() => onBlur("state")}
            ariaStateText={true}
          />
        </div>
        <div className="col-6 col-md-6 col-lg-3 pr-0 pl-10 mobile-pr-0 mobile-pl-10">
          <Input
            id="contact-user-zipcode"
            type="text"
            value={data?.zipcode}
            maxLength={7}
            autoComplete={true}
            placeholder={`${i18n?.zipcode}*`}
            label={`${
              labels && labels.zipCodeLabel
                ? labels.zipCodeLabel
                : i18n?.zipcode
            }*`}
            onChange={onInputChange}
            showError={error?.zipcode}
            errorMessage={error?.zipcode}
            onBlur={() => onBlur("zipcode")}
          />
        </div>
      </div>
      <div className="row mx-0">
        <div
          className={`col-12 col-sm-6 col-md-6 pl-0 ${
            isMobile ? "pr-0" : "pr-10"
          } mb-20`}
        >
          <Dropdown
            id="contact-user-country"
            data={countries.map(country => country?.name)}
            name="country"
            value={data?.country?.name ?? ""}
            label={`${i18n?.country}`}
            onChange={changeCountry}
            showError={error?.country}
            errorMessage={error?.country}
          />
        </div>
        <div
          className={`col-12 col-sm-6 col-md-6 pr-0 ${
            isMobile ? "pl-0" : "pl-10"
          }`}
        >
          <Input
            id="contact-user-phone"
            type="text"
            maxLength={60}
            placeholder={`${
              activeTab === HOME_OWNER_TAB
                ? i18n?.phoneNumber
                : i18n?.businessPhone
            }*`}
            label={`${
              activeTab === HOME_OWNER_TAB
                ? i18n?.phoneNumber
                : i18n?.businessPhone
            }*`}
            prefix={<img src={phoneIcon?.src} alt="" aria-hidden="true" />}
            value={data?.phone}
            onChange={e =>
              setData({ ...data, phone: convertToPhoneNumber(e.target.value) })
            }
            showError={error?.phone}
            errorMessage={error?.phone}
            onBlur={() => onBlur("phone")}
          />
        </div>
      </div>
    </div>
  )
}

export default ContactInfo
