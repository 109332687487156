import React, { useRef } from "react"
import cx from "classnames"
import _startCase from "lodash/startCase"

import CONSTANTS from "@/constants"
import { getAnalyticsData } from "@/components/ContactUs/v1/contactUsAnalytics"
const FormTabs = props => {
  const {
    activeTab = "",
    label = [],
    changeActive = () => {},
    analyticsData = "",
    i18n = {},
  } = props
  const tabRefs = useRef([])
  const {
    CREATE_ACCOUNT_TYPE: { HOME_OWNER = "", PROFESSIONAL = "" },
  } = CONSTANTS
  const HOME_OWNER_TAB = _startCase(HOME_OWNER)
  const PROFESSIONAL_TAB = _startCase(PROFESSIONAL)
  const handleKeyPress = (e, index) => {
    const { keyCode: tabKeycode = "" } = e

    if (tabKeycode === 37 && activeTab === PROFESSIONAL_TAB) {
      changeActive(HOME_OWNER_TAB)
      tabRefs?.current[index - 1]?.focus()
    } else if (tabKeycode === 39 && activeTab === HOME_OWNER_TAB) {
      changeActive(PROFESSIONAL_TAB)
      tabRefs?.current[index + 1]?.focus()
    }
  }

  return (
    <div className="tabs">
      <ul id="contactus-tab-list" className="tab-list" role="tablist">
        {label?.map((l, index) => (
          <li
            ref={el => (tabRefs.current[index] = el)}
            id={`${l}-tab`}
            role="tab"
            aria-selected={activeTab === l}
            tabIndex={activeTab === l ? 0 : -1}
            className={cx({
              "tab-list-item": true,
              "tab-list-active": activeTab === l,
              "gbh-data-layer": true,
            })}
            key={l}
            onClick={() => changeActive(l)}
            onKeyDown={e => handleKeyPress(e, index)}
            data-gbh-data-layer={`${getAnalyticsData(analyticsData, l)}`}
          >
            {i18n[`${l.toLowerCase()}`]}
          </li>
        ))}
      </ul>
    </div>
  )
}
export default FormTabs
