import React, { useEffect, useRef, useState } from "react"
import Collapsible from "react-collapsible"
import {
  getRandom,
  sanitizeInnerHtml,
  getShortenedUrlwithGeneral,
} from "@/utils/helper"
import arrowUp from "@/public/icons/arrow-up.svg"
import arrowDown from "@/public/icons/arrow-down.svg"
import { accordionUniqueId } from "@/utils"
import { getConfig } from "@/constants/config"

const Accordion = props => {
  const {
    isOpen = false,
    contentOverflow = false,
    trigger = false,
    handleOpen,
    handleClose,
    containerElementProps,
    isArrow = false,
    items,
    itemsOrder,
    id,
    titleKey,
    componentPosition,
    componentLinkType,
    accordionProperties: { expandedItems = [] } = {},
  } = props
  const [isExpanded, setisExpanded] = useState(true)
  const [general, setGeneral] = useState(null)
  const accordiumObject = useRef(null)
  const pageType = window.eventPageType

  const handleAccordion = () => {
    setisExpanded(true)
  }

  useEffect(() => {
    getConfig().then((config = {}) => {
      setGeneral(config?.general)
    })
  }, [])

  const CollapsibleHeader = ({ title, open = false }) => (
    <h2 className="cmp-accordion__header">
      <button
        className={`cmp-accordion__button ${
          open ? "cmp-accordion__button--expanded" : ""
        }`}
        onClick={handleAccordion}
        data-gbh-data-layer={`{"clickInternalLinks":"true","internalLinkPosition":"${
          componentPosition ? componentPosition : "accordion"
        }",
        "internalLinkZoneName":"n/a","internalLinkURL":"n/a","internalLinkName":"${title?.toLowerCase()}",
        "eventName":"${pageType?.toLowerCase()}:${title?.toLowerCase()}",
        "internalLinkType":"${pageType?.toLowerCase()}:${
          componentLinkType ? componentLinkType : "navigation"
        }",
        "eventType":"accordion",
        "eventAction":"${pageType?.toLowerCase()}:${title?.toLowerCase()}"}`}
        aria-expanded={open}
      >
        <span role="heading" aria-level="5" className="cmp-accordion__title">
          {title}
        </span>
        {!isArrow ? (
          <span className="cmp-accordion__icon" aria-hidden>
            <div className="line line-1"></div>
            <div className="line line-2"></div>
          </span>
        ) : (
          <span className="cmp-accordion__icon" aria-hidden>
            <div className="down-arrow">
              <img src={arrowDown.src} alt={"Arrow Down"} />
            </div>
            <div className="up-arrow">
              <img src={arrowUp.src} alt={"Arrow Up"} />
            </div>
          </span>
        )}
      </button>
    </h2>
  )

  const CollapsibleInnerText = ({ gridColumnClassName, faqText, title }) => {
    const finalText = sanitizeInnerHtml(
      getShortenedUrlwithGeneral(faqText, general)
    )
    return (
      <div
        data-accordian-title={title}
        className={`cmp-accordion__panel${
          isExpanded
            ? " cmp-accordion__panel--expanded"
            : " cmp-accordion__panel--hidden"
        }`}
      >
        <div className={`text ${gridColumnClassName}`}>
          <div className="cmp-text">{finalText}</div>
        </div>
      </div>
    )
  }

  return (
    <div
      id={id}
      ref={accordiumObject}
      className="cmp-accordion"
      data-placeholder-text="false"
    >
      {itemsOrder?.map((item, i) => {
        const title = items[item][titleKey]
        let gridColumnClassName = items[item]["appliedCssClassNames"]
        let faqText = items[item]["text"]

        if (!faqText) {
          faqText = ""
          const itemsOrderArr = items[item][":itemsOrder"]
          if (itemsOrderArr) {
            itemsOrderArr.forEach(itemTx => {
              faqText = `${faqText}${items[item][":items"][itemTx]["text"]}`
              gridColumnClassName = `${items[item][":items"][itemTx]["appliedCssClassNames"]}`
            })
          }
        }

        const isItemExpanded = expandedItems?.includes(item) ?? isOpen

        return (
          <React.Fragment key={`accordion-${i}-${getRandom()}`}>
            <div
              data-cmp-hook-accordion="item"
              className="cmp-accordion__item"
              id={`accordion-${i}-${getRandom()}`}
              data-cmp-data-layer={`{"accordion-${i}-${getRandom()}":{"@type":"kohler-us/components/accordion/item","dc:title":"${title}"}}`}
            >
              <Collapsible
                open={isItemExpanded}
                onTriggerOpening={handleOpen}
                onTriggerClosing={handleClose}
                containerElementProps={containerElementProps}
                overflowWhenOpen={contentOverflow ? contentOverflow : "hidden"}
                openedClassName="collapsible--open"
                tabIndex="0"
                triggerOpenedClassName="collapsible__trigger--open"
                trigger={
                  trigger ? trigger : <CollapsibleHeader title={title} />
                }
                triggerWhenOpen={
                  trigger ? (
                    trigger
                  ) : (
                    <CollapsibleHeader title={title} open={true} />
                  )
                }
                transitionTime={400}
                easing="ease-in-out"
                triggerElementProps={{
                  id: accordionUniqueId("collapsible-trigger", 9999, 1000),
                }}
                contentElementId={accordionUniqueId(
                  "collapsible-content",
                  9999,
                  1000
                )}
              >
                <CollapsibleInnerText
                  gridColumnClassName={gridColumnClassName}
                  faqText={faqText}
                  title={title}
                />
              </Collapsible>
            </div>
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default Accordion
