import React, { useState } from "react"
import cx from "classnames"
import { useDispatch } from "react-redux"
import { useDropzone } from "react-dropzone"
import { isMobile } from "react-device-detect"

import { uploadImage } from "@/store/features/genericSlice"

import closeIcon from "@/public/icons/close.svg"
import PdfIcon from "@/public/icons/pdf-grey.svg"

import Button from "@/components/core/Button/Button"

import { analyticsOnDelete } from "@/components/ContactUs/v1/contactUsAnalytics"

const UploadImage = props => {
  const dispatch = useDispatch()
  const [errors, setErrors] = useState([])
  const [status, setStatus] = useState("")
  const {
    imageList = [],
    updateList = () => {},
    i18n = {},
    activeTab = "",
    buttonAnalyticsData = "",
  } = props

  const activeTabForAnalytics = activeTab && activeTab?.toLowerCase()

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    const errList = rejectedFiles[0]?.errors?.map(el => el?.message)
    errList && setErrors(errList)
    if (!errList && errors?.length > 0) setErrors([])
    if (imageList?.length + acceptedFiles?.length <= 5) {
      setErrors([])
      try {
        const newImageList = await Promise.all(
          acceptedFiles?.map(async f => {
            const res = await dispatch(uploadImage(f)).unwrap()
            return Object.assign(f, {
              preview: URL.createObjectURL(f),
              comment: "",
              link: res?.path,
              textCount: 0,
            })
          })
        )
        updateList([...imageList, ...newImageList])
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
      }
    } else {
      setErrors([i18n?.uploadCountLimit])
    }
    if (rejectedFiles[0]?.errors[0]?.code === "file-invalid-type")
      setErrors([i18n?.errorFileType])
    if (rejectedFiles?.length > 5) setErrors([i18n?.uploadCountLimit])
    if (rejectedFiles[0]?.file?.size > 5e6)
      setErrors([i18n?.errorMaxFileSizeLimit])
  }

  const validator = file => {
    if (file?.size > 5e6) {
      return {
        message: i18n?.errorMaxFileSizeLimit,
      }
    }
    switch (file?.type) {
      case "image/jpeg":
      case "image/png":
      case "image/gif":
      case "application/pdf":
        break
      default:
        return {
          message: i18n?.errorFileType,
        }
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/png, image/jpg, image/jpeg, image/gif, application/pdf",
    maxFiles: 5,
    multiple: true,
    onDrop,
    validator,
  })

  const onAddComment = (v, id) => {
    const newList = imageList
    newList[id]["comment"] = v
    newList[id]["textCount"] = v?.length
    updateList([...newList])
  }

  const onDelete = id => {
    const newList = imageList?.filter(l => l !== id)
    analyticsOnDelete(activeTabForAnalytics, id)
    updateList(newList)
    setStatus(i18n?.ariaLabels?.imageRemove)
  }

  const handleFocus = e => {
    const { currentTarget: { classList = {} } = {} } = e
    classList?.add("active-comment")
  }

  const handleBlur = e => {
    const { currentTarget: { classList = {} } = {} } = e
    classList?.remove("active-comment")
  }

  return (
    <div
      className={`upload-image-container ${
        imageList?.length < 6 && imageList?.length !== 0 ? "file-5" : ""
      }`}
      id="contact-image-preview-container"
      tabIndex="0"
      role="presentation"
    >
      {imageList?.map((f, i) => (
        <div
          id="input-id-active"
          className="contact-us-image-preview"
          key={i}
          onClick={handleFocus}
          onBlur={handleBlur}
        >
          <div className="preview-wrapper">
            <img
              className={cx({ "image-box": f?.type !== "application/pdf" })}
              src={f?.type === "application/pdf" ? PdfIcon?.src : f?.preview}
              alt={f?.name}
            />
          </div>
          <textarea
            id={`image-placeholder-${i}`}
            placeholder={i18n?.commentBoxPlaceholder}
            value={f?.comment}
            onChange={e => onAddComment(e.target.value, i)}
            maxLength={100}
          />
          <span className="text-count">{f?.textCount} / 100</span>
          <span className="image-delete">
            <img
              tabIndex="0"
              className="custom-close-icone"
              onClick={() => onDelete(f)}
              src={closeIcon?.src}
              alt="close"
            />
          </span>
        </div>
      ))}
      {imageList?.length !== 5 && (
        <div className="drag-and-drop-container" {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="drag-and-drop-text-large">{i18n?.dragAndDrop}</div>
          <div className="drag-and-drop-text-small">{i18n?.orText}</div>
          <div className="drag-and-drop-upload-btn">
            <Button
              role="button"
              tabIndex="-1"
              className={buttonAnalyticsData ? "gbh-data-layer" : ""}
              data-gbh-data-layer={buttonAnalyticsData || null}
              type="ghost"
              label={i18n?.uploadFileButton}
              disabled={imageList?.length === 5 && isMobile}
              useDefaultClass={true}
            />
          </div>
        </div>
      )}
      <div role="region" aria-live="polite" className="aria-messages">
        {status}
      </div>
      {errors && (
        <p className="upload-image-container__error-msg">{errors[0]}</p>
      )}
    </div>
  )
}

export default UploadImage
