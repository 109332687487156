import Accordion from "@/components/ProductDetail/v3/ProductAccordion/ProductAccordion.view"

const ProductAccordion = props => {
  const {
    data: { accordionProperties = {}, componentLinkType = "" },
  } = props
  const {
    [":itemsOrder"]: itemsOrder,
    [":items"]: items,
    id,
  } = accordionProperties

  return (
    <div
      className={`accordion  ${accordionProperties?.appliedCssClassNames} productAccordionView`}
    >
      <Accordion
        accordionProperties={accordionProperties}
        componentLinkType={componentLinkType}
        items={items}
        itemsOrder={itemsOrder}
        id={id}
        textKey="text"
        titleKey="cq:panelTitle"
      />
    </div>
  )
}

export default ProductAccordion
