const KnowledgeArticleFiller = () => {
  return (
    <>
      <div className="kf-react-plp-container">
        <div className="kf-page-title">
          <div className="width-full">
            <h1 className="kf-page-title__title shimmer-loading__title shimmer mb-20" />
            <p className="kf-page-title__title shimmer-loading__title shimmer" />
          </div>
          <div className="kf-page-title__separator" />
          <div className="width-250">
            <h2 className="shimmer-loading__para shimmer " />
            <p className="shimmer-loading__para shimmer " />
          </div>
        </div>
        <div className="kf-page-title__total">
          <p className="shimmer-loading__para shimmer width-full" />
        </div>
      </div>
      <div className="kf-react-plp-container">
        <div className="kf-page-title">
          <div className="width-full">
            <h1 className="kf-page-title__title shimmer-loading__title shimmer mb-20" />
            <p className="kf-page-title__title shimmer-loading__title shimmer" />
          </div>
          <div className="kf-page-title__separator" />
          <div className="width-250">
            <h2 className="shimmer-loading__para shimmer " />
            <p className="shimmer-loading__para shimmer " />
          </div>
        </div>
        <div className="kf-page-title__total">
          <p className="shimmer-loading__para shimmer width-full" />
        </div>
      </div>
      <div className="kf-react-plp-container">
        <div className="kf-page-title">
          <div className="width-full">
            <h1 className="kf-page-title__title shimmer-loading__title shimmer mb-20" />
            <p className="kf-page-title__title shimmer-loading__title shimmer" />
          </div>
          <div className="kf-page-title__separator" />
          <div className="width-250">
            <h2 className="shimmer-loading__para shimmer " />
            <p className="shimmer-loading__para shimmer " />
          </div>
        </div>
        <div className="kf-page-title__total">
          <p className="shimmer-loading__para shimmer width-full" />
        </div>
      </div>
    </>
  )
}

export default KnowledgeArticleFiller
